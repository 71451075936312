<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import API from "@/utils/request";
export default  {
  name:  'App',
  mounted(){
    API.post("/api/user/login", {username:'tom',password:123456}).then(res => {
        if (res.code === '0') {
          sessionStorage.setItem("user", JSON.stringify(res.data))
        } 
      })
  },
}
</script>